import React, { useState } from "react";
import { Link } from "react-router-dom";
import { validationLogins } from "../middlewares/loginValidation";
import axios from "axios";
import config from "../config";

const Login = () => {
    const [formData, setFormData] = useState({ email: "", password: "" });
    const [errors, setErrors] = useState({});
    const [serverError, setServerError] = useState(""); // State for server errors
    const [loading, setLoading] = useState(false);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({ ...prevData, [name]: value }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setServerError(""); // Clear previous server error

        // Perform form validation
        const validationErrors = validationLogins(formData);

        // Update errors state
        setErrors(validationErrors);

        // Check if there are validation errors
        const hasValidationErrors = Object.values(validationErrors).some((error) => error !== "");

        if (hasValidationErrors) {
            setLoading(false);
            return; // Exit function if there are validation errors
        }

        try {
            // Send login request to the server
            const apiUrl = config.API_BASE_URL;
            const response = await axios.post(`${apiUrl}/api/login`, formData);

            // Extract token and userId from response data
            const { token, userId } = response.data;

            // Store token and userId in local storage
            localStorage.setItem("accessToken", token);
            localStorage.setItem("userId", userId);

            // Redirect to the homepage or perform other actions upon successful login
            console.log("Login successful. Token and UserId received:", token, userId);
            window.location.href = "/";
        } catch (error) {
            console.error("Error occurred during login:", error.response ? error.response.data.errorMessage : error.message);

            // Set server error message
            if (error.response && error.response.data.errorMessage) {
                setServerError(error.response.data.errorMessage);
            } else {
                setServerError("An unexpected error occurred. Please try again later.");
            }
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="d-flex justify-content-center align-items-center custom-background vh-100">
            <div className="first-left-accounts d-inline-block p-3 rounded" style={{ maxWidth: "400px", width: "100%" }}>
                <h1>Sign In</h1>
                <form onSubmit={handleSubmit}>
                    <div className="mb-3">
                        <label htmlFor="email" className="form-label">
                            <strong>Email</strong>
                        </label>
                        <input
                            type="email"
                            className="form-control"
                            id="email"
                            name="email"
                            placeholder="Enter email"
                            value={formData.email}
                            onChange={handleInputChange}
                        />
                        {errors.email && <span className="text-danger">{errors.email}</span>}
                    </div>
                    <div className="mb-3">
                        <label htmlFor="password" className="form-label">
                            <strong>Password</strong>
                        </label>
                        <input
                            type="password"
                            className="form-control"
                            id="password"
                            name="password"
                            placeholder="Enter password"
                            value={formData.password}
                            onChange={handleInputChange}
                        />
                        {errors.password && <span className="text-danger">{errors.password}</span>}
                    </div>
                    {serverError && <div className="text-danger mb-3">{serverError}</div>} {/* Render server error */}
                    <button type="submit" className="btn btn-success w-100" disabled={loading}>
                        {loading ? "Logging in..." : "Log in"}
                    </button>

                    <p>You agree to our terms and conditions</p>
                    <Link to="/signup" className="btn btn-default border w-100">
                        Create an Account
                    </Link>
                </form>
            </div>
        </div>
    );
};

export default Login;
